import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import useApi from '../api/useApi';

import './UserTeamsForm.css';

const UserTeamsForm = ({user, getDataCallback}) => {
  const {id: userId, teamId, teamName, role: teamRole} = user;

  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [url, setUrl] = useState(null);

  // Make sure that we have the user info before sending to the server
  useEffect(() => {
    if (userId) {
      setUrl(`/userTeamsData/${userId}`);
    }
  }, [userId]);

  // Fetch all the current user’s teams from the database.
  const {data: teamsData, loading: apiLoading, error: apiError} = useApi(url);

  useEffect(() => {
    if (teamsData) {
      setTeams(teamsData);
      if (teamId && teamName && teamRole) {
        // Set the initial state of the dropdown list
        setSelectedTeam(
          JSON.stringify({
            teamId: teamId,
            teamName: teamName,
            teamRole: teamRole,
          })
        );
      }
    }
  }, [teamsData, teamId, teamName, teamRole]);

  const handleTeamSelect = async (event) => {
    const selectedValue = event.target.value;
    setSelectedTeam(selectedValue);
    if (selectedValue) {
      const teamInfo = JSON.parse(selectedValue);
      getDataCallback(teamInfo);
    }
  };

  return (
    <>
      <form className="teams_form">
        {apiError && <div className="">{apiError}</div>}
        {apiLoading ? <div className="spinnerModal"></div> : ''}
        <fieldset>
          <div className="form-item">
            <label id="teams_label">Teams/Projects</label>
            <div>
              <select
                className="signup-form-select"
                name="team"
                type="text"
                value={selectedTeam}
                onChange={handleTeamSelect}
              >
                <option value="" disabled hidden>
                  Select another team
                </option>
                {teams &&
                  Array.isArray(teams) &&
                  teams.map((team) => (
                    <option
                      key={team.team_id}
                      value={JSON.stringify({
                        teamId: team.team_id,
                        teamName: team.team_name,
                        teamRole: team.role,
                      })}
                    >
                      Name: {team.team_name} | Role: {team.role}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </fieldset>
      </form>
    </>
  );
};

UserTeamsForm.propTypes = {
  getDataCallback: PropTypes.func,
  user: PropTypes.object,
};
export default UserTeamsForm;
